import { RoomStates } from '@internal/state/room';
import { Sticky } from '@internal/ui/css/Sticky';
import { AccountButton } from 'components/AccountButton';
import NavLoggedIn from 'components/NavLoggedIn';
import SEO from 'components/SEO';
import { RoomState } from 'pages/[room]/components/RoomState';
import { AppComponent } from 'typescript/typings';

interface PageError {
    code: number;
}

const gifs = {
    notFound: 'https://giphy.com/embed/b1YtwNsVRgyJ5IbvM3',
    error: 'https://giphy.com/embed/WUrgA8xOSUJj6JZoJC',
};

const PageError: AppComponent<PageError> = ({ code }) => {
    const getErrorCopy = () => {
        if (code === 400) {
            return 'Hm... looks like that’s not a valid page. Are you sure that’s the right link?';
        }

        if (code === 404) {
            return 'Hmm... we can’t find that page. Are you sure that’s the right link?';
        }

        // Fallback to `RoomState` default
        return undefined;
    };

    const getPageTitle = () => {
        if (code === 400) {
            return 'Invalid page';
        }

        if (code === 404) {
            return 'Page not found';
        }

        return 'Something went wrong';
    };

    return (
        <div className="flex min-h-full w-full flex-1 flex-col items-stretch">
            <Sticky sticky="top">
                <NavLoggedIn className="transition-all [&.stuck]:bg-tertiary-100 [&.stuck]:shadow-popover">
                    <AccountButton />
                </NavLoggedIn>
            </Sticky>
            <SEO title={getPageTitle()} noindex />
            <div className="flex-1 flex-col p-5 pt-0 flex-center">
                <RoomState
                    className="max-w-[600px]"
                    state={code === 500 ? RoomStates.Error : RoomStates.RoomNotFound}
                >
                    {getErrorCopy()}
                </RoomState>
                <iframe
                    src={code >= 400 && code < 500 ? gifs.notFound : gifs.error}
                    frameBorder="0"
                    className="giphy-embed pointer-events-none h-[280px] w-[280px] rounded-xl"
                    allowFullScreen={false}
                />
            </div>
        </div>
    );
};

export default PageError;
